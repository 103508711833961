import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { mm } from '../config'
import { i18n } from '../shared/utils'
import Navbar from './components/Navbar'
import MediaManagerIFrame from './components/MediaManagerIFrame'

import * as css from './styles.scss'


export default class MediaManager extends Component {

  static propTypes = {
    dialog: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      images: [],
      searchValue: ''
    }
  }

  @autobind
  handleSearch({ target }) {
    this.setState({
      [target.id]: target.value
    })
  }

  @autobind
  handleChange({ target }) {
    this.setState({ images: target.value })
  }

  @autobind
  handleSubmit() {
    this.props.dialog.result = this.state.images.map((image) => {
      // there is a bug in the way the data come back from the mm. it's wrapped in 18n twice.
      return i18n(image.i18n, 'draft.original.src')
    })
    this.props.dialog.onCommit()
  }

  renderNavbar() {
    return (<Navbar
      navbarStyle={css.mediaManagerTitlebar}
      onCancel={this.props.dialog.onCancel}
      onSubmit={this.handleSubmit}
      id="mediaManager"
      submitDisabled={!this.state.images.length}
      handleInputChange={this.handleSearch}
      searchValue={this.state.searchValue}
      imageCount={this.state.images.length}
    />)

  }

  render() {

    return (
      <div className="grid-frame vertical">
        {this.renderNavbar()}
        <MediaManagerIFrame
          className={classNames(css.mediaManagerBlock, 'grid-block')}
          imageSpec={this.props.dialog.imageSpec}
          multiple={this.props.dialog.multiple}
          maxImages={this.props.dialog.maxImages}
          projectId={this.props.dialog.projectId}
          projectName={this.props.dialog.projectName}
          lang={this.props.dialog.lang}
          src={mm.url}
          onChange={this.handleChange}
          searchValue={this.state.searchValue}
        />
      </div>
    )
  }

}
