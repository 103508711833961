/* eslint no-underscore-dangle: "off" */
import {
  action,
  computed,
  extendObservable,
  isObservableMap,
  observable,
  reaction,
  toJS,
  transaction,
} from 'mobx'
import { debounce } from 'core-decorators'
import { defaultChannel } from '../config'
import { i18n, getTemplateNameById, getUserNameById } from '../shared/utils'
import Model from '../shared/model'
import {
  talkative,
  validate,
} from '../shared/decorators/model'
import { lockable } from '../shared/decorators/communicator'
import { maxBy } from '../shared/obj'
import { CircularDependencyFreeStore } from '../CircularDependencyFreeStore'
import { VersionStore } from './version/reducers'
import PlaceholderAccessor from './helpers/PlaceholderAccessor'
import PlaceholderDataInserter from './helpers/PlaceholderDataInserter'

const getProjectChannel = (projectStore) => {
  return projectStore && projectStore.hasCurrent
    ? projectStore.current.channelShortcut
    : null
}

const getItemChannel = (item, fallbackChannel) => {
  return (
    (item.channels && item.channels[0])
    // the api is a littlebid messed up here. so sometimes the channels array
    // doesn't exist, but instead a complete channel object
    || (item.channel
      && (typeof item.channel === 'string'
        ? item.channel
        : item.channel.shortcut))
    || fallbackChannel
  )
}

@talkative
@validate({
  name: 'string|minLen:2',
})
@lockable
export default class Article extends Model {
  static INVALID_INSTANCE_VERSION = -1;

  static INVALID_MODULE_ID = -1;

  static INVALID_ORDER_ID = -1;

  // -- Default properties

  static autoSavable = true;

  // DO NOT declare NON-mobx props here, they would become
  // prototype props
  static get defaultProps() {
    return {
      instanceVersion: Article.INVALID_INSTANCE_VERSION,
      versionNumber: 1,
      moduleId: Article.INVALID_MODULE_ID,
      orderId: Article.INVALID_ORDER_ID,
      isInPage: false,
      channels: [],
      categories: [],
      i18n: {},
      versions: [],
    }
  }

  // MOBX observables
  // the template id of this article

  @observable channels = [];

  @observable categories = [];

  @observable versions = [];

  @observable versionNumber = -1;

  @observable updatedAt = '';

  @observable createdAt = '';

  @observable i18n = {};

  @observable meta = {};

  @observable usage = [];

  // Ids of projects where the article is currently used
  @observable projectIds = [];

  @observable phAccessor = null;

  // whether or not this item is marked as active
  @observable isActive = false;

  @observable isCreatingVersion = false;

  @observable complete = false;

  @observable usesProjectChannel = false;

  @observable publicationNotAllowed = false;

  @observable releasePublicationLockAt = null;

  @observable mandatoryFieldsMissing = false;

  // Set this to cause a placeholder update after the next save
  @observable isImageInPlaceholderChanged = false;

  // this getter is for consistence
  @computed get channelShortcut() {
    const channelShortcut = this.channel
    return channelShortcut
  }

  @computed get placeholder() {
    return this.phAccessor ? this.phAccessor.asJSON : null
  }

  @computed get placeholderLength() {
    return this.phAccessor ? this.phAccessor.length : null
  }

  @computed get asJSON() {
    return this.getJSON()
  }

  @computed get versionType() {
    const versions = this.versions

    const latest = maxBy(versions, version => version.versionNumber)

    if (!latest) {
      return 'draft'
    }

    if (this.versionNumber === latest.versionNumber) {
      return 'currentRelease'
    }

    if (!this.isVersion) {
      return 'draft'
    }

    return 'version'
  }

  get contentType() {
    return 'article'
  }

  get isVersion() {
    return (
      this.instanceVersion
      && this.instanceVersion !== this.constructor.INVALID_INSTANCE_VERSION
    )
  }

  // an article is editable if and only if the following
  // criteria are met
  //
  // it IS NOT locked
  // it HAS NO versionNumber
  // is IS NOT deleted
  get isEditable() {
    return (
      this.isInEditableStatus
      && !this.isLocked
      && !this.isVersion
      && !this.isDeleted
    )
  }

  /**
   * @private
   */
  get isInEditableStatus() {
    return this.isInEditableVersion && !this.isLockedByOther()
  }

  get isInEditableVersion() {
    return !this.isVersion
  }

  // TODO: This needs to be implemented properly. Just what "is locked".
  // Is locked also true in case the current user locked the element?
  get isLocked() {
    return false
  }

  get isDeleted() {
    return !!this.deletedAt
  }

  // getters for easy access
  @computed get name() {
    return i18n(this, 'name', this.createdIso)
  }

  get templateName() {
    return getTemplateNameById(this.templateId)
  }

  get createByName() {
    return getUserNameById(this.createdBy)
  }

  get updatedByName() {
    return getUserNameById(this.updatedBy)
  }


  constructor(store, rawData = {}, opts = {}) {
    if (!store) {
      console.error('model creation requires a store')
    }
    // set standard properties (like store) and calls parse, set, and handleModelCreated
    super(store, rawData, opts)

    if (this.phAccessor) {
      this.phDataInserter = new PlaceholderDataInserter(this.phAccessor)
    }

    this.initLockable()
    this.initValidate()
    this.previouslySavedJSON = null

    const { projectStore } = CircularDependencyFreeStore
    // Only and ONLY if the project channel changed, update the article channel
    reaction(
      () => getProjectChannel(projectStore, this),
      (projectChannel) => {
        const shouldAutosave = this.autoSave
        // disable any automatic saving on all changes made in here
        this.autoSave = false

        transaction(() => {
          // update the channel if needed in placeholder accessors and writers
          if (projectChannel) {
            if (projectChannel !== this.channel) {
              this.channel = projectChannel
              this.phAccessor.setChannel(projectChannel)
              this.phDataInserter.setChannel(projectChannel)
            }
            this.usesProjectChannel = true
          }
          else {
            this.usesProjectChannel = false
          }
        })

        // reset autoSaving to original state
        // NOTE: Due to MobX timeout of 1, we need atleast 2 here
        // or we will be too fast - leading to extra saves to the article model
        setTimeout(() => {
          this.autoSave = shouldAutosave
        }, 2)
      },
      {
        fireImmediately: true,
        name: 'Change channel after project switch',
      }
    )
  }

  // trigged after super() constructor is called
  handleModelCreated() {
    // once the model was created, create a version store
    this.versionStore = new VersionStore({
      article: this,
    })
  }

  parse(data = {}) {
    if (data.data) {
      data = data.data
    }

    data.id = data.id ? data.id * 1 : undefined

    if (
      (!data.meta || Array.isArray(data.meta))
      && !Object.keys(this.meta || {}).length
    ) {
      data.meta = {}
    }

    // if there is no instance version explicitly set it to INVALID_INSTANCE_VERSION
    if (!this.instanceVersion && this.instanceVersion !== -1) {
      data.instanceVersion
        = typeof data.instanceVersion !== 'undefined'
          ? data.instanceVersion
          : Article.INVALID_INSTANCE_VERSION
    }


    if (data.placeholder) {
      if (Object.prototype.toString.call(data.placeholder) !== '[object Object]') {
        data.placeholder = {}
      }

      // ensure there is a data object for each channel in the channels array
      if (data.channels && Array.isArray(data.channels)) {
        data.channels
          .map((channel) => {
            // ensure the channel is only the shortcut string
            return typeof channel === 'string' ? channel : channel.shortcut
          })
          .forEach((channel) => {
            data.placeholder[channel] = data.placeholder[channel] || {}
          })
      }
    }

    // set a default channel if one hasn't yet been set
    data.channel = getItemChannel(data, this.channel || defaultChannel)

    return data
  }

  @action
  setMeta(newMeta) {
    if (!this.meta) {
      this.meta = {}
    }
    extendObservable(this.meta, newMeta)
    Object.keys(this.meta).forEach((key) => {
      if (this.meta[key] === undefined) {
        delete this.meta[key]
      }
    })
    this.save()
  }

  prepareDataForSet(data) {
    if ('name' in data) {
      i18n(data, 'name', this.createdIso, data.name)
      delete data.name
    }
    return data
  }

  @debounce(500)
  saveWithDebounce(opts = {}) {
    this.save(opts)
  }

  save(opts = {}) {
    if (!this.isValid) {
      return Promise.reject(new Error('Model data invalid'))
    }
    this.saving = true
    const asJSON = this.getJSON()
    const asString = JSON.stringify(asJSON)

    // if not forcing and the previous save is the same as current, skip saving
    if (!opts.force && asString === this.previouslySavedJSON) {
      return { action: 'noSaveNeeded' }
    }
    // set immediate to prevent unnecessary saves
    this.previouslySavedJSON = asString

    return this.store.save(this, opts)
  }

  set(data, opts = {}) {
    const shouldAutosave = this.autoSave
    this.autoSave = false

    // always first clone the data before we do something with it
    data = this.parse(this.prepareDataForSet(this.clone(data)))

    // if the model has an id already remove it from the new data to ensure
    // the existing id will not be overwritten
    if (this.id) {
      delete data.id
      const ignoreKeys = [
        'createdAt',
        'createdBy',
        'createdChannel',
        'createdIso',
        'sourceId',
        'templateId',
        'store',
      ]
      ignoreKeys.forEach((key) => {
        if (this[key]) {
          delete data[key]
        }
      })
    }

    // remove the placeholders from the data, as they will get special treatment
    let placeholder = data.placeholder
    delete data.placeholder

    // sets and extends the observables
    extendObservable(this, data)

    if (!placeholder) {
      placeholder = {}
    }

    if (
      this.versionStore
      && this.versionStore.collection
      && this.versionStore.collection.length < this.versions.length
    ) {
      // if the versionStore was never fully loaded (partial load comes from a page load)
      this.handleModelCreated()
    }

    // only update the placeholders if
    // a) this is a new model which has no id yet or
    // b) the action causing the set was NOT a call to 'save'
    // c) an image in the placeholder was changed
    if (
      !this.id
      || opts.action !== 'save'
      || this.isImageInPlaceholderChanged
    ) {
      this.isImageInPlaceholderChanged = false
      this.updatePlaceholderObject(placeholder)
    }

    if (!this.editDistance) {
      // TODO make this size reflect version change
      this.editDistance = `${Math.floor(Math.random() * 21) + 25}`
    }

    // if the article was complete once, it cannot become incomplete again
    this.complete = this.complete || !!placeholder

    this.autoSave = shouldAutosave

    if (opts.action === 'save') {
      this.save()
    }

    // set data to not trigger a save when data equals initial data
    if (!this.previouslySavedJSON) {
      const asString = JSON.stringify(this.getJSON())
      this.previouslySavedJSON = asString
    }
  }

  updatePlaceholderObject(newPlaceholder = {}) {
    // In case the placeholder object is empty, but the article has already
    // an id (and thus exists in the database), ignore setting empty objects
    // which is synonymeous of clearing an article.
    if (
      this.id
      && this.phAccessor
      && this.phAccessor.length
      && (!newPlaceholder || !Object.keys(newPlaceholder).length)
    ) {
      return
    }

    // only create the placeholder accessor if it does not exist yet
    if (!this.phAccessor || !this.phAccessor.update) {
      this.phAccessor = new PlaceholderAccessor(
        this,
        newPlaceholder,
        this.channel
      )
      this.phDataInserter = new PlaceholderDataInserter(
        this.phAccessor,
        this.channel
      )
    }
    else {
      if (this.phAccessor.update) {
        this.phAccessor.update(newPlaceholder)
      }
      else {
        console.warn('creating article but missing update method')
      }
    }
  }

  validateChannelShortcut(channel) {
    // this is a bug and should not be there
    if (
      typeof channel !== 'string'
      // these are REALLY string and not typeof/instanceof checks!
      || channel === '[object Object]'
      || channel === 'undefined'
    ) {
      console.warn(`Article Model Warning: Invalid channel ${channel}.`)
      return false
    }
    return true
  }

  arePlaceholdersEmpty(placeholder) {
    return !(isObservableMap(placeholder)
      ? placeholder.size
      : Object.keys(placeholder).length)
  }

  getDataInPlaceholder(pid) {
    return this.phAccessor.get(pid, 'value')
  }

  getEditorState(pid) {
    return this.phAccessor.getEditorState(pid)
  }

  removeDataInPlaceholder(pid) {
    return this.phAccessor.remove(pid)
  }

  getPlaceholderAccessor() {
    return this.phAccessor
  }

  placeDataInPlaceholder(type, pid, data) {
    if (type === 'image') {
      this.isImageInPlaceholderChanged = true
      return this.phDataInserter.placeImageDataInPlaceholder(pid, data, this)
    }
    if (type === 'file') {
      return this.phDataInserter.placeFileDataInPlaceholder(pid, data, this)
    }
    if (type === 'video') {
      return this.phDataInserter.placeVideoDataInPlaceholder(pid, data, this)
    }

    // eslint-disable-next-line max-len
    throw new Error(
      `Article cannot place data of the type \`${
        type || 'undefined'
      }\` in a placeholer.`
    )
  }

  getJSON() {
    return {
      id: this.id,
      languages: toJS(this.languages),
      placeholder: this.phAccessor ? this.phAccessor.asJSON : {},
      i18n: toJS(this.i18n),
      channels: toJS(this.channels),
      categories: toJS(this.categories),
      createdChannel: this.createdChannel,
      createdIso: this.createdIso,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      deletedBy: this.deletedBy,
      instanceVersion: this.instanceVersion,
      versionNumber: this.versionNumber,
      templateId: this.templateId,
      meta: toJS(this.meta),
      sourceId: this.sourceId,
      publicationNotAllowed: this.publicationNotAllowed,
      releasePublicationLockAt: this.releasePublicationLockAt,
      projectIds: toJS(this.projectIds),
      mandatoryFieldsMissing: this.mandatoryFieldsMissing,
      editorVersion: this.editorVersion,
    }
  }
}
