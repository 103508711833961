import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import { autobind } from 'core-decorators'

import { FormattedMessage } from '../../../translations'
import ContentLoadingBox from '../ContentLoadingBox'
import { deepGet } from '../../obj'
import FilterPageCommand from '../../commands/filter-page'
import Icon from '../../icons'

import { pageStore } from '../../../page/reducers'
import { i18n } from '../../utils'
import { store as uiStore } from '../../../ui'

const css = /* typeof window === 'undefined' ? {} : */require('./styles.scss')

class InternalLinkList extends Component {

  static propTypes = {
    pageId: PropTypes.number.isRequired,
    handleOpenArticle: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      articles: [],
      pageList: {}
    }

    this.localProjects = []
    this.ps = null
  }

  componentDidMount() {
    this.ps = new PerfectScrollbar('#article-list-container', {
      suppressScrollX: true
    })

    pageStore.getInternalLinks(this.props.pageId)
      .then((articles) => {
        // Gather all pageIds to load
        const pageIds = []
        articles.forEach((article) => {
          article.pages.forEach((page) => {
            pageIds.push(page.id)
          })
          article.projects.forEach((project) => {
            this.localProjects.push(project)
          })
        })

        return new FilterPageCommand('id', pageIds, 'parentPages').exec()
          .then(() => {

            // Create basic scroll indicator
            const pageList = {}
            articles.forEach((el) => {
              if (el.id && el.pages && el.pages.length > 1) {
                pageList[el.id] = 0
              }
            })

            this.setState({
              isLoading: false,
              articles,
              pageList
            })
          })
      })
  }

  getPagePath(pageId) {
    return pageStore.getPagePath(pageId)
  }

  getPageName(page) {
    const pageName = deepGet(page, `i18n.${page.createdIso}.name`) || ''
    const project = this.localProjects.find(el => el.id === page.projectId * 1)
    const channelName = i18n(project, 'channel.i18n.name', uiStore.language)
    const projectName = `${channelName} ${project.label}`
    const projectLanguage = project.language ? `| ${project.language}` : ''

    const projectDesc = project
      ? ` (${projectName} ${projectLanguage})`
      : ''
    return pageName + projectDesc
  }

  /* Currently unused */
  @autobind
  handleOpenArticle(article) {
    return this.props.handleOpenArticle(article)
  }

  handleScrollUp(article) {
    const pageList = this.state.pageList
    if (pageList[article.id] === 0) {
      // Bail out if scroll not possible
      return
    }

    pageList[article.id] += 50

    this.setState({
      pageList
    })
  }

  handleScrollDown(article) {
    const pageList = this.state.pageList
    const maxScroll = (article.pages.length - 1) * -50 // row height
    if (pageList[article.id] <= maxScroll) {
      // Bail out if scroll not possible
      return
    }

    pageList[article.id] -= 50

    this.setState({
      pageList
    })
  }

  renderPageDescription(article) {
    const { pageList } = this.state
    const minScroll = 0
    const maxScroll = (article.pages.length - 1) * -50 // row height
    return [
      <div key="1" className={css.pageList} style={{ marginTop: `${pageList[article.id]}px` }}>
        {article.pages.map((page) => {
          return (<div key={page.id} className={css.pageInfoWrapper}>
            <div className={css.name}>
              {this.getPageName(page)}
            </div>
            <div className={classNames(css.info, css.name)}>
              {this.getPagePath(page.id)}
            </div>
          </div>)
        })}
      </div>,
      article.pages.length > 1
        ? <div key="2" className={css.scrollWrapper}>
          <div className={classNames(css.scrollButton, pageList[article.id] === minScroll ? css.disabled : '')}
            onClick={() => this.handleScrollUp(article)}
          >
            <Icon name="ion ion-ios-arrow-up" />
          </div>
          <div className={classNames(css.scrollButton, pageList[article.id] === maxScroll ? css.disabled : '')}
            onClick={() => this.handleScrollDown(article)}
          >
            <Icon name="ion ion-ios-arrow-down" />
          </div>
        </div>
        : null
    ]
  }

  renderRow(article) {
    const isArticleInPage = article.type === 'article' && article.pages.length

    return (<div className={css.row}>
      <div className={css.col}>
        <div
          // currently unused, needed for 'no access' dialog and linking to project/page/article
          // className={classNames(isArticleInPage ? css.link : '', css.name)}
          // onClick={() => {
          //   if (!isArticleInPage) {
          //     return null
          //   }
          //   return this.handleOpenArticle(article)
          // }}
          className={css.name}
        >
          {article.name
            ? article.name
            : <FormattedMessage
              className={css.italic}
              id="page-internal-link-dialog.redirect"
            />
          }
        </div>
        <div className={css.info}>
          <FormattedMessage
            id="page-internal-link-dialog.internal-link-info"
            values={{ count: article.count }}
          />
        </div>
      </div>

      <div className={classNames(css.col, css.pageCol)}>
        {article.pages.length > 0
          ? this.renderPageDescription(article)
          : <FormattedMessage
            className={css.italic}
            id="page-internal-link-dialog.no-page"
          />}
      </div>
    </div>)
  }

  renderLoader() {
    return (<ContentLoadingBox
      spinner
      message={{
        id: 'placeholder-empty'
      }}
    />)
  }

  render() {
    const { isLoading, articles } = this.state

    return (<div className={classNames(css.internalLinkList, 'internal-link-list')}>
      <div className={css.header}>
        <FormattedMessage id="article.article" />
        <FormattedMessage id="page.page" />
      </div>

      <div
        id="article-list-container"
        className={css.articleListContainer}
      >
        {isLoading
          ? this.renderLoader()
          : articles.map(article => this.renderRow(article))}
      </div>

    </div>)
  }
}
export default InternalLinkList
