"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ViewModelPool = /** @class */ (function () {
    function ViewModelPool(_a) {
        var store = _a.store;
        this.store = store;
        this.pool = {};
    }
    ViewModelPool.prototype.get = function (spec) {
        var name = spec.class.name;
        if (name in this.pool) {
            return this.pool[name];
        }
        var ValidationModelClass = spec.class;
        var model = new ValidationModelClass({ store: this.store });
        this.pool[name] = model;
        return model;
    };
    return ViewModelPool;
}());
exports.default = ViewModelPool;
