"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandInjectorContextPropTypes = void 0;
var React = __importStar(require("react"));
var hoistNonReactStatics_1 = require("../../hoistNonReactStatics");
var mobx_react_1 = require("mobx-react");
var validate_arguments_1 = __importStar(require("../../validate-arguments"));
var Provider_1 = require("./Provider");
exports.CommandInjectorContextPropTypes = {
    viewModelPool: Provider_1.ViewModelPoolPropType,
};
// Inspired by mobx-react
function isStateless(component) {
    // `function() {}` has prototype, but `() => {}` doesn't
    // `() => {}` via Babel has prototype too.
    return !(component.prototype && component.prototype.render);
}
function validateViewModels(viewModels) {
    if (!Array.isArray(viewModels)) {
        throw new Error("`viewModelConnector` expects an array of `viewModelSpec`s as first " +
            "argument!");
    }
    viewModels.map(function (viewModel) {
        validate_arguments_1.default(viewModel, {
            'class': validate_arguments_1.ValidationHelper.isPresent,
            'class.name': validate_arguments_1.ValidationHelper.isString,
        });
    });
}
function createViewModelInjector(viewModels, component) {
    var displayName = "inject-view-model-" +
        (component.displayName || component.name ||
            (component.constructor && component.constructor.name) || "Unknown");
    var Injector = /** @class */ (function (_super) {
        __extends(Injector, _super);
        function Injector() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.wrappedInstance = null;
            _this.injectorRef = function (instance) {
                _this.wrappedInstance = instance;
            };
            return _this;
        }
        Injector.prototype.componentDidMount = function () {
            var _this = this;
            viewModels.forEach(function (viewModelSpec) {
                var viewModel = _this.getViewModel(viewModelSpec);
                if (viewModel) {
                    viewModel.collect(viewModelSpec.data, _this.props);
                }
            });
        };
        Injector.prototype.componentWillUnmount = function () {
            var _this = this;
            viewModels.forEach(function (viewModelSpec) {
                var viewModel = _this.getViewModel(viewModelSpec);
                if (viewModel) {
                    viewModel.dispose(viewModelSpec.data, _this.props);
                }
            });
        };
        Injector.prototype.getViewModels = function (props) {
            var _this = this;
            return viewModels
                .reduce(function (memo, viewModelSpec) {
                var viewModel = _this.getViewModel(viewModelSpec);
                viewModel.init(viewModelSpec.data, props);
                memo[_this.getViewModelName(viewModelSpec)] = viewModel;
                return memo;
            }, {});
        };
        Injector.prototype.getViewModel = function (viewModelSpec) {
            return this.context.viewModelPool.get(viewModelSpec);
        };
        Injector.prototype.getViewModelName = function (viewModelSpec) {
            return viewModelSpec.as || viewModelSpec.class.name;
        };
        Injector.prototype.render = function () {
            var newViewModels = this.getViewModels(this.props);
            var newProps = __assign(__assign({}, this.props), { viewModels: __assign(__assign({}, (this.props.viewModels || [])), newViewModels) });
            if (!isStateless(component)) {
                newProps.ref = this.injectorRef;
            }
            return React.createElement(component, newProps);
        };
        Injector.displayName = displayName;
        Injector.wrappedComponent = undefined;
        Injector.contextTypes = exports.CommandInjectorContextPropTypes;
        Injector = __decorate([
            mobx_react_1.observer
        ], Injector);
        return Injector;
    }(React.Component));
    // Static fields from component should be visible on the generated Injector
    hoistNonReactStatics_1.hoistNonReactStatics(Injector, component, []);
    Injector.wrappedComponent = component;
    return Injector;
}
function default_1(viewModels) {
    return function (componentClass) {
        validateViewModels(viewModels);
        return createViewModelInjector(viewModels, componentClass);
    };
}
exports.default = default_1;
