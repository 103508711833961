import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'

import Button from './Button'

import { store as contextStore } from '../../../context'
import { store as uiStore } from '../../../ui'

import linkDialog from '../../../shared/containers/LinkDialog'
import classPickerDialog from '../../../shared/containers/ClassPickerDialog'

import {
  transfromToLinkObject,
  transformToLinkAttributes
} from '../../../shared/link-helper'


class Toolbar extends React.Component {

  static propTypes = {
    className: PropTypes.string
  }

  handleClassPicker(target) {
    return classPickerDialog({
      data: {}
    }).then((result) => {
      target.ref.handleClassName(result)
      return result
    }).catch((ex) => {
      console.error(ex)
      return {}
    })
  }

  handleLinkPrompt(target) {
    const data = {
      appearance: '',
      content: target.selectedText || '',
      externalScheme: 'https',
      externalUrl: '', // Will be parsed by transfromToLinkObject
      href: 'https://',
      rel: '',
      target: '',
      title: '',
      type: 'external',
      ...target.entityData,

      exists: !!target.entityData,
      currentContextType: 'text',
      contentNotPossible: !target.ref.isLinkTextEditable, // If a text is selected, the content cannot be changed currently
    }

    return linkDialog({
      data: transfromToLinkObject(data)
    }).then((result) => {
      if (result.status === 'cancelled') {
        return false
      }
      if (result.status === 'unlink') {
        return target.ref.handleRemoveLink()
      }

      return target.ref.handleLink(transformToLinkAttributes(result))
    }).catch((ex) => {
      console.error(ex)
      return {}
    })
  }

  @autobind
  handleClick(button) {
    const { target } = contextStore

    if (target && target.ref) {
      if (target.ref.handleToggleInlineStyle && button.type === 'inline') {

        // Trigger command on editor
        target.ref.handleToggleInlineStyle(button.cmd)
      }
      else if (target.ref.handleToggleBlockType && button.type === 'block') {
        // Trigger command on editor
        target.ref.handleToggleBlockType(button.cmd)
      }
      else if (button.type === 'undoRedo') {
        // Handle extra functionality linkDialog
        target.ref.handleUndoRedo(button.cmd)
      }
      else if (button.type === 'link') {
        // Handle extra functionality linkDialog
        this.handleLinkPrompt(target)
      }
      else if (button.type === 'classname') {
        // Handle extra functionality classPickerDialog
        this.handleClassPicker(target)
      }
    }
  }

  render() {
    const activeButtons = contextStore.editorButtons
    const draftEditorButtons = uiStore.draftEditorButtons.map((button) => {

      button.isActive = false

      if (activeButtons.length && activeButtons.find(el => el === button.cmd)) {
        // Button is active
        button.isActive = true
      }

      return button
    })

    return (
      <div
        className={classNames(
          this.props.className,
          'toolbar',
          'editor-toolbar',
          'v-align'
        )}
      >
        {draftEditorButtons.map((button) => {
          if (!button.isVisible) {
            return null
          }
          return (<Button
            key={button.name}
            button={button}
            handleClick={this.handleClick}
          />)
        })}
      </div>
    )
  }
}

export default Toolbar
