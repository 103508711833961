"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var command_1 = require("../../../../../shared/lib/command");
var ITEM_INDEX_LAST_ITEM = -1;
var RemoveContentFromWidgetWidgetMacroCommand = /** @class */ (function (_super) {
    __extends(RemoveContentFromWidgetWidgetMacroCommand, _super);
    function RemoveContentFromWidgetWidgetMacroCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RemoveContentFromWidgetWidgetMacroCommand.prototype.exec = function () {
        var _a = this.data, openForEditing = _a.openForEditing, context = _a.context;
        var model;
        // Ensures the content exists
        return this.dispatch(this.data.commands.RemoveContentFromWidgetCommand, this.getDataForRemoveCommand());
        /*  .then(() => {
            return this.dispatch(
              this.data.commands.SelectContentInWidgetCommand,
              this.getDataForSelectCommand()
            )
          })*/
    };
    RemoveContentFromWidgetWidgetMacroCommand.prototype.getDataForRemoveCommand = function () {
        var _a = this.data, position = _a.context.position, widgetId = _a.widgetId;
        var removeAtPosition = position;
        return {
            removeAtPosition: removeAtPosition,
            widgetId: widgetId
        };
    };
    RemoveContentFromWidgetWidgetMacroCommand.prototype.getDataForSelectCommand = function () {
        var context = this.data.context;
        return {
            context: context,
            // always select the last item in the list
            item: null,
            index: ITEM_INDEX_LAST_ITEM
        };
    };
    // This command doesn't use these stores, but it's subcommands do. Defining
    // them here anyways guarantees that all subcommands only are executed if
    // these stores are available here already.
    RemoveContentFromWidgetWidgetMacroCommand.storeRequirements = {
        widget: true,
    };
    RemoveContentFromWidgetWidgetMacroCommand.validationSpec = {
        context: command_1.MacroCommand.ensureData.isPresent,
        'context.position': command_1.MacroCommand.ensureData.isPresent,
    };
    return RemoveContentFromWidgetWidgetMacroCommand;
}(command_1.MacroCommand));
exports.default = RemoveContentFromWidgetWidgetMacroCommand;
