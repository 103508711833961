import { createConfirmation } from 'react-confirm'
import ClassPickerDialog from '../components/Dialog/ClassPickerDialog'

const classPickerDialog = createConfirmation(ClassPickerDialog)

export default function (confirmation, options = {}) {

  if (typeof confirmation !== 'string') {

    options = {
      modal: {
        ...confirmation.data,
      }
    }
    confirmation = ''

  }

  return classPickerDialog({ confirmation, options })

}
