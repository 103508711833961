"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var extractStores = function (requiredStores, allStores) {
    return Object.keys(requiredStores).reduce(function (memo, storeName) {
        memo[storeName] = allStores[storeName];
        return memo;
    }, {});
};
var Dispatcher = /** @class */ (function () {
    function Dispatcher(dispatcherOpts) {
        Object.assign(this, dispatcherOpts);
    }
    /**
     * Main interface for command execution. Pass in the **class** of a command and
     * some data.
     * @method exec
     * @param  {ICommand} Command A command **class**.
     * @param  {any}      data    Any data that the command should use. It will be
     * handed off to the commands `set` method. So make sure that method can
     * handle the data.
     * @return {[type]}           The command execution result.
     */
    Dispatcher.prototype.exec = function (Command, data, eventsBindings) {
        var command = this.createCommand(Command, data);
        return this.execCommand(command);
    };
    /**
     * Can be used to create a command manually. Pass in the **class** of a
     * command and some data.
     * @method createCommand
     * @param  {ICommand}    Command A command **class**.
     * @param  {any}         data    Any data that the command should use. It will
     * be handed off to the commands `set` method. So make sure that method can
     * handle the data.
     * @return {Command}             An instance of the command class handed in.
     */
    Dispatcher.prototype.createCommand = function (Command, data) {
        return new Command(__assign({}, data));
    };
    /**
     * Execute a command **instance**.
     * @method execCommand
     * @param  {Command}   command        A command instance.
     * @param  {any}       additionalData Any data that the command should use. It
     * will be handed off to the commands `set` method. So make sure that method
     * can handle the data.
     * @return {[type]}                   The command execution result.
     */
    Dispatcher.prototype.execCommand = function (command, additionalData) {
        this.prepareCommand(command, additionalData);
        command.dispatcher = this;
        return command.exec();
    };
    Dispatcher.prototype.prepareCommand = function (command, additionalData) {
        if (additionalData) {
            command.set(additionalData);
        }
        if (command.constructor.storeRequirements) {
            var storeDependencies = extractStores(command.constructor.storeRequirements, this.store);
            command.set({
                store: storeDependencies
            });
        }
    };
    return Dispatcher;
}());
exports.default = Dispatcher;
