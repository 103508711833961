/**
 * @description
 * This module is the main entry point for the application.
 * It starts the router and connects to the history. Then it renders the Root
 * Component to the #root element.
 *
 */

import { useRouterHistory } from 'react-router'
import createBrowserHistory from 'history/lib/createBrowserHistory'
import request from 'superagent'


// =============================================================================
// Import styles
// -----------------------------------------------------------------------------

// Import font awesome icons. The stuff that's imported here may be
// adjusted in the files after the '!':
// import 'font-awesome-webpack!path/to/font-awesome.config.(js|less)'
import 'font-awesome-webpack'
import 'ionicons-webpack'

import './editor/scribe/skins/index.scss'
import 'react-datetime/css/react-datetime.css'

import './styles/core.scss'
// =============================================================================


// =============================================================================
// Globals
// -----------------------------------------------------------------------------

// apply extensions to native objects like Promise of Date
import './native-extensions'
import './loggers'
import './livereload'

// =============================================================================

import config from './config'

// Get the routes and the store.
import makeRoutes from './routes'
import store from './reducers'

import App from './app'

// Import the Root and the store. Then render the root and pass all
// data to it.

import initErrorTracker from './initErrorTracker'
import setPageTemplates from './setPageTemplates'
import adjustConfig from './adjustConfig'
// import ensureIntlPolyfill from './ensureIntlPolyfill'


/* global __BASENAME__:true */
const browserHistory = useRouterHistory(createBrowserHistory)({
  basename: JSON.stringify(process.env.BASENAME)
})
/* global __BASENAME__:false */

const routes = makeRoutes()

// eslint-disable-next-line new-cap
const app = App({
  root: document.getElementById('root'),
  browserHistory,
  routes,
  store
})


app.load()

request
  .get(window.APP_CONFIG_URL.replace(':80/', '/'))
  .set('Content-Type', 'application/json')
  .withCredentials()
  .end((err, res = { body: '' }) => {
    if (!err && res.ok) {
      const rawResponse = res.text.replace('window.GENEVA_CONFIG = ', '')
      window.GENEVA_CONFIG = JSON.parse(rawResponse)

      // ensureIntlPolyfill(() => {
      adjustConfig(config)
      initErrorTracker(store)
      setPageTemplates(store, config)
      app.start()
      // })
    }
    else {
      app.error({ err })
    }
  })
