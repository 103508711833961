exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-shared-components-Navbar-styles__navbarSection___3ECuI{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.src-shared-components-Navbar-styles__navbarSection___3ECuI:last-child{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.src-shared-components-Navbar-styles__navbarFixedSection___2wRsn{-webkit-box-flex:1;-ms-flex:1;flex:1}.src-shared-components-Navbar-styles__navbarFlexSection___3J8x-{-webkit-box-flex:1;-ms-flex:1;flex:1}@media (min-width: 1200){.src-shared-components-Navbar-styles__navbarFixedSection___2wRsn{min-width:400px;-webkit-box-flex:0;-ms-flex:0;flex:0}.src-shared-components-Navbar-styles__navbarFlexSection___3J8x-{max-width:400px;-webkit-box-flex:1;-ms-flex:1;flex:1}}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/shared/components/Navbar/styles.scss"],"names":[],"mappings":"AAAA,4DACE,8BAAA,6BAAA,uBAAA,mBACA,oBAAA,oBAAA,aACA,uBAAA,oBAAA,2BACA,yBAAA,sBAAA,kBAAmB,CAJrB,uEAOI,qBAAA,kBAAA,wBAAyB,CAC1B,iEAID,mBAAA,WAAA,MAAO,CACR,gEAGC,mBAAA,WAAA,MAAO,CACR,yBAGC,iEACE,gBACA,mBAAA,WAAA,MAAO,CACR,gEAEC,gBACA,mBAAA,WAAA,MAAO,CACR,CAAA","file":"styles.scss","sourcesContent":[".navbarSection {\n  flex-direction: row;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n\n  &:last-child {\n    justify-content: flex-end;\n  }\n}\n\n.navbarFixedSection {\n  flex: 1;\n}\n\n.navbarFlexSection {\n  flex: 1;\n}\n\n@media(min-width:1200) {\n  .navbarFixedSection {\n    min-width: 400px;\n    flex: 0;\n  }\n  .navbarFlexSection {\n    max-width: 400px;\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"navbarSection": "src-shared-components-Navbar-styles__navbarSection___3ECuI",
	"navbarFixedSection": "src-shared-components-Navbar-styles__navbarFixedSection___2wRsn",
	"navbarFlexSection": "src-shared-components-Navbar-styles__navbarFlexSection___3J8x-"
};