import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'

import { FormattedMessage } from '../../../translations'
import Dropdown, { DropdownOpener, Entry } from '../Dropdown'
import { dispatcher } from '../../lib/command'
import { CircularDependencyFreeStore } from '../../../CircularDependencyFreeStore'
import projectDialog from '../../containers/ProjectDialog'

const css = require('./styles.scss')

@dispatcher
@observer
class LimitedProjectDropdown extends Component {
  static propTypes = {
    contentStores: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    onRenderEntry: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired
  }

  @autobind
  handleOpenProjectDialog() {
    projectDialog({
      currentProject: this.props.currentProject,
      projectStore: this.props.contentStores.project,
      authStore: this.props.contentStores.auth,
    }).then((result) => {
      if (!result || !result.newProjectId) {
        throw new Error('missing project id to switch to')
      }
      // find if project already is in collection and clear the page collection since it will cause issues
      CircularDependencyFreeStore.pageStore.requireNewPage = true

      const requestedProject = this.props.contentStores.project.getById(result.newProjectId)
      if (requestedProject) {
        return this.props.contentStores.project.setCurrent(requestedProject)
      }
      return this.props.contentStores.project.load(result.newProjectId, { setCurrent: true })
    }).then(() => {
      const newProjectId = this.props.contentStores.project.current.id
      this.context.dispatch(this.props.commands.EditItemCommand, {
        router: this.props.router,
        type: 'publish',
        id: newProjectId,
        projectId: newProjectId,
      })
    })
  }

  /**
   * Mix of displaying ProjectGroup name, but need project id for the specific
   *  language the user was previously in
   * @param {*} lastOpenedProjectId -
   * @param {*} projectStore -
   * @param {*} currProject -
   */
  getProjectEntry(lastOpenedProjectId, projectStore, currProject) {
    const baseProject = projectStore.projectPartialsCollection.find(
      proj => proj.id === lastOpenedProjectId
    )
    // if previous project request was not valid
    if (!baseProject) {
      return null
    }
    const baseGroup = projectStore.getGroupById(baseProject.projectGroupId)
    if (!baseGroup) {
      return null
    }

    // todo: update after fendt sturcture is updated
    const name = (
      <span>
        <span className="channel">{baseProject.channelName}</span> -{' '}
        {baseGroup.name} - {baseProject.language}
      </span>
    )

    return {
      name,
      state: {
        disabled: false,
        checked: currProject.id === lastOpenedProjectId,
      },
      id: baseProject.id,
      key: `project-${baseProject.id}`,
    }
  }

  renderEntry(lastOpenedProjectId) {
    const {
      currentProject,
      contentStores: { project },
    } = this.props
    const entry = this.getProjectEntry(
      lastOpenedProjectId,
      project,
      currentProject
    )

    return this.props.onRenderEntry(entry)
  }

  renderDropdownHeader(lastOpenedProjects) {
    return [
      <li key="header" className="dropdown-header">
        <FormattedMessage id="project.limited-project-dropdown-header" />
      </li>,
      lastOpenedProjects.map(lastOpenedProject => this.renderEntry(lastOpenedProject)
      ),
      <hr key="hr" />,
    ]
  }

  render() {
    const {
      disabled,
      className,
      contentStores: { project },
    } = this.props
    const lastOpenedProjects = project.projectHistory || []

    if (!project.projectPartialsCollection.length) {
      return null
    }

    return (
      <Entry>
        <DropdownOpener
          className={className}
          disabled={disabled || !project.projectPartialsCollection.length}
          arrow
        >
          <FormattedMessage id="project.select-project" />
        </DropdownOpener>
        <Dropdown
          className={classNames(css.limitedProjectDropdown, 'geneva-dropdown')}
        >
          {lastOpenedProjects.length
            ? this.renderDropdownHeader(lastOpenedProjects)
            : null}
          <li className="dropdown-entry" onClick={this.handleOpenProjectDialog}>
            <FormattedMessage id="project.all" />
          </li>
        </Dropdown>
      </Entry>
    )
  }
}

export default LimitedProjectDropdown
