import { zip } from 'zip-js/WebContent/zip.js'

const requestFileSystem =  window.webkitRequestFileSystem
  || window.mozRequestFileSystem
  || window.requestFileSystem

window.zip = {
  workerScriptsPath: '/scripts/WebContent/'
}
zip.workerScriptsPath = '/scripts/WebContent/'

function onerror(message) {
  alert(message)
}

const tmpFilename = 'geneva-archive.gar'

const createTempFile = () => {

  const MAX_FILE_SIZE = 4 * 1024 * 1024 * 1024

  return new Promise((resolve/* , reject*/) => {

    requestFileSystem(

      window.TEMPORARY,

      MAX_FILE_SIZE,

      (filesystem) => {

        function create() {
          filesystem.root.getFile(tmpFilename, {
            create: true
          }, (zipFile) => {
            resolve(zipFile)
          })
        }

        filesystem.root.getFile(tmpFilename, null, (entry) => {
          entry.remove(create, create)
        }, create)

      }

    )

  })


}

class Model {

  constructor(creationMethod = 'File') {
    this.zipFileEntry = null
    this.zipWriter = null
    this.creationMethod = creationMethod
    this.URL = window.URL || window.webkitURL || window.mozURL
  }

  createZipWriter(writer) {
    return new Promise((resolve, reject) => {
      zip.createWriter(writer, resolve, reject, onerror)
    })
  }

  addFiles(files, { onInit, onAdd, onProgress }) {
    const func = () => {}
    let addIndex = 0

    let onEnd
    const promise = new Promise((resolve) => {
      onEnd = resolve
    })

    const nextFile = () => {
      const file = files[addIndex];
      (onAdd || func)(file)
      this.zipWriter.add(file.name, new zip.BlobReader(file), () => {
        addIndex++
        if (addIndex < files.length) {
          nextFile()
        }
        else {
          onEnd()
        }
      }, (onProgress || func))
    }


    if (this.zipWriter) {
      nextFile()
      return promise
    }

    let writerPromise

    if (this.creationMethod === 'Blob') {
      writerPromise = Promise.resolve(new zip.BlobWriter('application/zip'))
    }
    else {
      writerPromise = createTempFile((fileEntry) => {
        this.zipFileEntry = fileEntry
        return new zip.FileWriter(this.zipFileEntry)
      })
    }

    writerPromise.then(writer => this.createZipWriter(writer))
      .then((zipWriter) => {
        this.zipWriter = zipWriter;
        (onInit || func)()
        nextFile()
      })

    return promise
  }

  getBlobURL() {
    return new Promise((resolve/* , reject*/) => {
      this.zipWriter.close((blob) => {

        const blobURL = this.creationMethod === 'Blob'
          ? this.URL.createObjectURL(blob)
          : this.zipFileEntry.toURL()

        resolve(blobURL)
        this.zipWriter = null

      })
    })
  }

  getBlob() {
    return new Promise((resolve/* , reject*/) => {
      this.zipWriter.close(resolve)
    })
  }

}


export default class FileProcessor {

  static mimes = {
    jpg: 'image/jpg',
    jpeg: 'image/jpeg',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    gif: 'image/gif',
    png: 'image/png',
  }

  static processableFileTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png'
  ]

  constructor(opts = {}) {

    this.processableFileTypes = opts.processableFileTypes
    || FileProcessor.processableFileTypes

    this.shouldReadFile = opts.readFile
  }

  trigger(...args) {
    console.log(...args)
  }

  getTitle(filename) {

    return filename
      .replace(/\.[^\.]*$/, '')
      .replace(/(^|_|-|\.)(\S)/g, (match, group1, group2) => ` ${group2.toUpperCase()}`)
      .replace(/by\s[\s\S]*$/i, '')

  }

  getType(filename) {

    let type = ''
    const fileEnding = filename.match(/\.([^\.]+)$/)[1]

    if (fileEnding in FileProcessor.mimes) {
      type = FileProcessor.mimes[fileEnding]
    }
    else {
      console.info(`TODO: Register filetype for ${fileEnding}`)
    }

    return type

  }

  processFile(rawFile) {

    const defaultProcessedFile = {
      title: this.getTitle(rawFile.name),
      filename: rawFile.name,
      result: ''
    }

    return (this.shouldReadFile
      ? this.readFile(rawFile)
      : Promise.resolve({ origFile: rawFile }))
      .then(({ origFile, processedFile = defaultProcessedFile }) => {
        return {
          name: origFile.name,
          title: processedFile.title.trim(),
          url: processedFile.filename,
          content: processedFile.result,
          type: origFile.type,
          size: origFile.size,
          file: origFile,
          success: true
        }
      })

  }

  readFile(origFile) {

    return new Promise((resolve, reject) => {

      const title = this.getTitle(origFile.name)
      const reader = new FileReader()

      this.trigger('file-processing:started')

      reader.onload = ({ target }) => {

        this.trigger('file-processing:finished')
        resolve({
          origFile,
          processedFile: target
        })

      }

      reader.onerror = (err) => {
        console.error(err)
        reject(err)
      }

      reader.title = title
      reader.filename = origFile.name

      console.info('TODO: set reader in dropTarget object')
      reader.readAsDataURL(origFile)

    })

  }

  processFilesToZIP(files) {

    return new Promise((resolve/* , reject*/) => {

      let current = 0
      const total = files.length

      const model = new Model('Blob') // alternative: File

      model.addFiles(files, {
        onInit: () => {
          this.trigger('zipping:started')
        },
        onAdd: (/* file */) => {
          current += 1
          this.trigger('zipping:progress',
            current,
            total
          )
        }
      })
        .then(() => {
          this.trigger('zipping:finished')
          model.getBlob()
            .then((blob) => {
              blob.title = blob.name = tmpFilename
              blob.success = true
              resolve([blob])
            })
        })

    })

  }

  processFiles(files) {

    if (this.canProcessFilesLocally(files)) {
      return Promise.all(Array.prototype.map.call(files, file => this.processFile(file)))
    }

    return this.processFilesToZIP(files)

  }

  canProcessFilesLocally(files) {

    if (files.length > 1) {
      return false
    }

    const file = files[0]

    return file.type
      && (this.processableFileTypes.indexOf('*') > -1
      || this.processableFileTypes.indexOf(file.type) > -1)

  }
}
