/**
 * @description
 * This file contains some logic that overwrites livereloads default reload
 * function to reload only the currently opened tempalate (if possible)
 */
import store from '../reducers'

const lrReady = () => {

  // eslint-disable-next-line no-proto
  const origPerformReload = window.LiveReload.__proto__.performReload
  // eslint-disable-next-line no-proto
  window.LiveReload.__proto__.performReload = function performReload(message) {
    const parts = message.path.split(':')

    if (parts.length === 3) {

      console.log(`LiveReload performing template refresh: ${message.path}`)

      // eslint-disable-next-line no-unused-vars
      const [customer, type, shortcut] = parts

      const storeName = type === 'article'
        ? 'template'
        : `${type}Template`

      const tmplStore = (
        'default' in store ? store.default : store
      )[storeName]

      if (tmplStore) {
        const tmpl = tmplStore.collection.find(t => t.shortcut === shortcut)

        if (tmpl) {
          tmplStore.actionResetModule(tmpl.id)
        }
        if (type !== 'page') {
          // reload current page
          // ('default' in store ? store.default : store).pageTemplate.actionResetModule()
        }
      }

      return null

    }

    return origPerformReload.call(this, message)
  }
}

if (process.env.LIVERELOAD_PORT) {

  if (window.LiveReload) {
    lrReady()
  }
  else {
    document.addEventListener('LiveReloadConnect', lrReady, true)
  }

}
