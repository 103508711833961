import * as path from 'path'
const frontendVersion = require('../../package.json').version

if (typeof window !== 'undefined' && window.Element) {
  // eslint-disable-next-line global-require
  require('element-closest')
}

const environment = {
  development: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
}[process.env.NODE_ENV || 'development']

global.GENEVA_CONFIG = global.GENEVA_CONFIG || {
  customer: 'default',
}

global.GENEVA_CONFIG.uploadSettings = global.GENEVA_CONFIG.uploadSettings || {
  image: {
    fileTypes: '.jpeg, .png, .tif, .zip, .gif',
    fileSize: {
      singleUpload: 50000000,
      multiUpload: 100000000,
    },
    imageMaxHeight: 1080,
    imageMaxWidth: 1920
  }
}

// a atring array of mime types that the system can handle itself and
// that should not be processed (zipped) ever
global.GENEVA_CONFIG.processableFileTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'application/vnd.ms-excel', // xls
  'application/vnd.ms-excel.sheet.macroenabled.12', // xlsm - macro
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
]

global.GENEVA_CONFIG.editor = global.GENEVA_CONFIG.editor || {}
global.GENEVA_CONFIG.editor = {
  ...global.GENEVA_CONFIG.editor,
  buttons: global.GENEVA_CONFIG.editor.buttons || [
    'bold',
    'italic',
    'indent',
    'outdent',
    'underline',
    'strikeThrough',
    'superscript',
    'subscript',
    'foreColor',
    'fontStyle',
    'removeFormat',
    'removeLinebreak',
    'linkPrompt',
    'insertTable',
    'insertOrderedList',
    'insertUnorderedList',
    'undo',
    'redo',
    'cleanup',
  ],
  buttonOptions: global.GENEVA_CONFIG.editor.buttonOptions || {
    insertTable: {
      subCommands: {
        changeLayoutMode: {
          default: 'fixed',
        },
        changeAppearance: {
          default: 'plain',
          values: [
            {
              value: 'plain',
              name: 'no-border',
            },
            {
              value: 'bordered',
              name: 'border',
            },
          ],
        },
      },
    },
  },
  tagNames: global.GENEVA_CONFIG.editor.tagNames || {
    BOLD: 'b',
    ITALIC: 'i',
    STRIKETHROUGH: 'strike',
    SUPERSCRIPT: 'sup',
    SUBSCRIPT: 'sup',
  }
}

// // enable all table options
// if (global.GENEVA_CONFIG.editor.buttons.indexOf('insertTable') > -1) {
//   global.GENEVA_CONFIG.editor.buttons.push(
//     'insertTableRow',
//     'insertTableColumn',
//     'removeTableRow',
//     'removeTableColumn',
//   )
// }

if (!global.GENEVA_CONFIG.csrfTokens) {
  global.GENEVA_CONFIG.csrfTokens = {}
}

const api = {
  SCHEME: process.env.API_SCHEME || 'https:',
  // eslint-disable-next-line max-len
  HOST:
    process.env.API_HOST
    || 'beta-wm-api.geneva.works'
    || 'fendt-beta-wm-api.geneva.works'
    || 'api.seitz.05.geneva.works'
    || 'api.geneva.works'
    || 'geneva.dev',
  PORT: process.env.API_PORT || 80,
  BASEPATH: process.env.API_BASEPATH || 'public/',
}

const apiV2 = {
  SCHEME: process.env.API_V2_SCHEME || 'https:',
  // eslint-disable-next-line max-len
  HOST:
    process.env.API_V2_HOST
    || 'beta-wm-api.geneva.works'
    || 'fendt-beta-wm-api.geneva.works'
    || 'api.seitz.05.geneva.works'
    || 'api.geneva.works'
    || 'geneva.dev',
  PORT: process.env.API_V2_PORT || 80,
  BASEPATH: process.env.API_V2_BASEPATH || 'public/',
}

const renderer = {
  SCHEME: process.env.RENDERER_SCHEME || 'https:',
  HOST: process.env.RENDERER_HOST || 'geneva.dev',
  PORT: process.env.RENDERER_PORT || 3010,
  BASEPATH: process.env.RENDERER_BASEPATH || 'render/',
}

const config = Object.assign(
  global.GENEVA_CONFIG,
  {
    host: process.env.HOST || 'localhost',
    port: process.env.PORT,

    AD_SUPPORT: (process && process.env && process.env.AD_SUPPORT) || false,

    VERSION: typeof frontendVersion !== 'undefined' && frontendVersion ? frontendVersion : '0.0.0',

    defaultChannel: 'web',

    apiScheme: api.SCHEME || 'https:',
    apiHost: api.HOST,
    apiPort: api.PORT || 80 || 8000 || 9000,
    apiBasePath: api.BASEPATH || 'cms2/public/api/v1/',
    apiVersions: {
      v1: {
        apiScheme: api.SCHEME || 'https:',
        apiHost: api.HOST,
        apiPort: api.PORT || 80 || 8000 || 9000,
        apiBasePath: api.BASEPATH || 'cms2/public/api/v1/',
      },
      v2: {
        apiScheme: apiV2.SCHEME || 'https:',
        apiHost: apiV2.HOST,
        apiPort: apiV2.PORT || 80,
        apiBasePath: apiV2.BASEPATH || 'cms2/backend/public/',
      },
    },

    ws: {
      url:
        process.env.WS_HOST
        || 'http://communicator.dev.geneva.works:5030'
        || 'http://localhost:8082',
    },

    // plugins: {},

    mm: {
      url:
        process.env.MM_IFRAME_URL
        || 'http://mm.geneva.dev/public/iframe/index.html',
    },


    // this should be coming from the backend 'config' under env.services
    bynder: {
      url: process.env.BYNDER_IFRAME_URL || 'https://wave-trial.getbynder.com/'
    },

    am: {
      url: process.env.AM_URL || null, // 'http://am.fendt.05.geneva.works/'
      api: process.env.AM_API_URL || 'http://beta-api.geneva.works'
    },

    ENV_CM: 'CM',
    ENV_PM: 'PM',
    ENV_PREVIEW: 'PREVIEW',
    ENV_PUBLISH: 'PUBLISH',

    // 0: no reduction
    // 1 div in PageRenderer is removed,
    // 2 geneva-content div removed + PageRender div
    domReduction: process.env.DOM_REDUCTION || 0,

    schemePath: path.resolve(__dirname, '..', 'data', 'schemes'),

    articleTemplateBasePath: `/templates/${global.GENEVA_CONFIG.customer}/article`,
    articleRenderContainerFile: `/templates/${global.GENEVA_CONFIG.customer}/article/render-container.html`,

    pageTemplateBasePath: `/templates/${global.GENEVA_CONFIG.customer}/page`,

    widgetTemplateBasePath: `/templates/${global.GENEVA_CONFIG.customer}/widget`,

    // needed for the Renderer
    customLocal: {
      api: {
        url: 'customLocal/:id/',
        version: 'v2',
      },
      file: {
        path: './customLocal/:language/store.json',
      },
    },

    // Sizes and frames taken from https://www.webmobilefirst.com/en/devices/
    previewResolutions: global.GENEVA_CONFIG.previewResolutions || {
      phone: {
        icon: 'ion-iphone',
        text: 'Smartphone',
        options: [
          {
            name: 'Apple iPhone X',
            width: '375',
            height: '812',
            svgHeight: '931',
            svgWidth: '917',
            frame: 'modernFrame',
          },
          {
            name: 'Apple iPhone 12/13/14',
            width: '390',
            height: '844',
            svgHeight: '970',
            svgWidth: '956',
            frame: 'modernFrame',
          },
          {
            name: 'Samsung Galaxy S22/23',
            width: '360',
            height: '780',
            svgHeight: '997',
            svgWidth: '1333',
            frame: 'samsungGalaxyS22',
          },
          {
            name: 'Google Pixel 5',
            width: '393',
            height: '851',
            svgHeight: '1433',
            svgWidth: '1022',
            frame: 'googlePixel5'
          },
        ],
      },
      tablet: {
        icon: 'ion-ipad',
        text: 'Tablet',
        options: [
          {
            name: 'Apple iPad Air 4',
            width: '820',
            height: '1180',
            svgHeight: '2320',
            svgWidth: '1635',
          },
          {
            name: 'Samsung Galaxy Tab S7',
            width: '800',
            height: '1280',
            svgHeight: '2505',
            svgWidth: '1595',
          },
        ],
      },
      desktop: {
        icon: 'ion-monitor',
        text: 'Desktop',
        options: [
          {
            // special handling for 'Default' in preview
            name: 'Default',
            width: '100%',
            height: '100vh',
            svgHeight: '0',
            svgWidth: '0',
          },
          {
            name: 'Laptop',
            width: '1024',
            height: '582',
            svgHeight: '0',
            svgWidth: '0',
          },
          {
            name: 'Laptop L',
            width: '1440',
            height: '612',
            svgHeight: '0',
            svgWidth: '0',
          },
        ],
      },
    },

    previewDefaultResolution: global.GENEVA_CONFIG.previewDefaultResolution || {
      type: 'desktop',
      option: 'Default',
    },
  },
  environment
)

module.exports = config
