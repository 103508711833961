"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
var validate_1 = require("./validate");
var methods = __spreadArrays(Object.keys(validate_1.validate || {})
    .filter(function (method) { return /^is[A-Z]/.test(method); }), [
    'isPresent',
    'oneOf',
    'oneOfType',
    'either',
    'raw',
    'get'
]);
var addMethod = function (target, method, defineGetter, defineMethod) {
    if (!(method in target)) {
        if (/^is[A-Z]/.test(method)) {
            var prop = method.replace(/^is([A-Z])/, function (all, match1) {
                return match1.toLowerCase();
            });
            Object.defineProperty(target, method, {
                get: defineGetter(prop, method),
                enumerable: true
            });
            return;
        }
        target[method] = defineMethod(method);
    }
};
var ValidationHelper = /** @class */ (function () {
    function ValidationHelper() {
        var _this = this;
        this.spec = {};
        methods.forEach(function (method) {
            addMethod(_this, method, function (prop) {
                return function () {
                    this.spec[prop] = true;
                    return this;
                };
            }, function (method) {
                return function (opts) {
                    if (opts === void 0) { opts = true; }
                    this.spec[method] = opts;
                    return this;
                };
            });
        });
    }
    ValidationHelper.prototype.raw = function (opts) {
        Object.assign(this.spec, opts);
    };
    ValidationHelper.prototype.get = function () {
        return this.spec;
    };
    Object.defineProperty(ValidationHelper.prototype, "isPresent", {
        get: function () {
            this.spec.presence = true;
            return this;
        },
        enumerable: false,
        configurable: true
    });
    return ValidationHelper;
}());
methods.forEach(function (method) {
    try {
        addMethod(ValidationHelper, method, function (prop, method) {
            return function () {
                var helper = new ValidationHelper();
                return helper[method];
            };
        }, function (method) {
            return function (opts) {
                if (opts === void 0) { opts = true; }
                var helper = new ValidationHelper();
                return helper[method](opts);
            };
        });
    }
    catch (ex) {
        if (method === 'length') {
            return; // silently skip length for now
        }
        console.warn("ValidationHelper: Skipping method `" + method + "`");
    }
});
var H = ValidationHelper;
exports.default = H;
