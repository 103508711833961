import { autorun } from 'mobx'
export default (store) => {
  if (global.Raven) {

    global.Raven.setTagsContext({
      environment: global.GENEVA_CONFIG.customer,
    })

    global.Raven.setExtraContext({
      api_version: global.GENEVA_CONFIG.API_VERSION,
    })

    autorun('Set the user context for Sentry', () => {
      if (store.auth.user && store.auth.user.name) {
        global.Raven.setUserContext({
          id: store.auth.user.id,
          username: store.auth.user.name,
          email: store.auth.user.email
        })
      }
    })

    autorun('Set the language context in Sentry', () => {

      if (store.ui && store.ui.language) {
        global.Raven.setTagsContext({
          locale: store.ui.language,
        })
      }

      if (store.ui && store.ui.contentLanguage) {
        global.Raven.setTagsContext({
          contentLocale: store.ui.contentLanguage,
        })
      }

    })
  }
}
