"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var command_1 = require("../../../../../shared/lib/command");
// TODO add type defs
var CreateAndInsertNewContentIntoWidgetCommand = /** @class */ (function (_super) {
    __extends(CreateAndInsertNewContentIntoWidgetCommand, _super);
    function CreateAndInsertNewContentIntoWidgetCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CreateAndInsertNewContentIntoWidgetCommand.prototype.exec = function () {
        var _this = this;
        var _a = this.data, item = _a.item, openForEditing = _a.openForEditing;
        var model;
        // Ensures the content exists
        return this.dispatch(this.data.commands.CreateContentCommand, item)
            .then(function (content) {
            model = item[item.type] = content;
            return _this.dispatch(_this.data.commands.InsertContentIntoWidgetCommand, _this.getInsertionCommandData());
        })
            // May re-insert upon request - Handles selection after insert
            /*    .then(() => {
                  return this.dispatch(
                    this.data.commands.SelectContentInWidgetCommand,
                    {
                      context,
                      item
                    }
                  )
                }) */
            .then(function () {
            if (model && openForEditing) {
                _this.dispatch(_this.data.commands.EditItemCommand, _this.getEditingCommandData(model));
            }
        });
    };
    CreateAndInsertNewContentIntoWidgetCommand.prototype.getInsertionCommandData = function () {
        var _a = this.data, item = _a.item, page = _a.page, position = _a.context.position, widgetId = _a.widgetId;
        var insertionPosition = position;
        return {
            insertionPosition: insertionPosition,
            item: item,
            page: page,
            widgetId: widgetId
        };
    };
    CreateAndInsertNewContentIntoWidgetCommand.prototype.getEditingCommandData = function (model) {
        var router = this.data.router;
        return {
            router: router,
            id: model.id,
            type: model.contentType,
        };
    };
    // This command doesn't use these stores, but it's subcommands do. Defining
    // them here anyways guarantees that all subcommands only are executed if
    // these stores are available here already.
    CreateAndInsertNewContentIntoWidgetCommand.storeRequirements = {
        page: true,
        widget: true,
        article: true
    };
    CreateAndInsertNewContentIntoWidgetCommand.validationSpec = {
        context: command_1.MacroCommand.ensureData.isPresent,
        'context.position': command_1.MacroCommand.ensureData.isPresent,
        item: command_1.MacroCommand.ensureData.isPresent,
        'item.type': command_1.MacroCommand.ensureData.isPresent.isString,
        'widgetId': command_1.MacroCommand.ensureData.isNumber,
        openForEditing: command_1.MacroCommand.ensureData.isBoolean,
        router: command_1.MacroCommand.ensureData.isPresent,
    };
    return CreateAndInsertNewContentIntoWidgetCommand;
}(command_1.MacroCommand));
exports.default = CreateAndInsertNewContentIntoWidgetCommand;
