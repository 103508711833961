import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'

import classNames from 'classnames'

import { formatMessage, FormattedMessage } from '../../../translations'

import GenevaButton from '../../../ui/components/GenevaButton'

import { deepGet } from '../../obj'
import config from '../../../config'

import { Dialog } from '.'


const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')


@confirmable
class ClassPickerDialog extends Component {

  static propTypes = {
    show: PropTypes.bool, // indicates if the dialog is shown or not.
    proceed: PropTypes.func, // call to close the dialog with promise resolved.
    cancel: PropTypes.func, // call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object, // arguments of your confirm function
  };

  constructor(props) {
    super(props)

    this.state = {
      classOptions: deepGet(config, 'editor.buttonOptions.stylePicker') || [],
      currentClass: ''
    }
  }

  getResultData() {
    return {
      cmd: this.state.currentClass,
    }
  }

  @autobind
  handleCommit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    return this.props.proceed(this.getResultData())
  }

  @autobind
  handleCancel() {
    return this.props.dismiss()
  }

  @autobind
  handleChange({ target }) {
    this.setState({
      currentClass: target.id
    })
  }

  @autobind
  renderFooter() {
    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button float"
          onClick={this.handleCancel}
        >
          <FormattedMessage id="fore-color-dialog.cancel" />
        </GenevaButton>

        <GenevaButton
          type="submit"
          className="small primary button"
          onClick={this.handleCommit}
        >
          <FormattedMessage id="fore-color-dialog.ok" />
        </GenevaButton>
      </div>
    )
  }

  render() {
    const { classOptions, currentClass } = this.state

    return (
      <Dialog
        className={css.classPickerDialog}
        overlayClassName={css.classPickerDialogOverlay}
        isOpen={this.props.show}
        onRequestClose={this.props.dismiss}
        modalStyle={{
          content: {
            width: '480px',
          },
        }}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: 'fore-color-dialog.font-color' })}
      >
        {classOptions.map((option) => {
          return (
            <label
              className={classNames(css.optionLabel, option.name === currentClass ? 'active' : '')}
              key={option.name}

              htmlFor={`radio-option-${option.name}`}
              onClick={this.handleChange}
            >
              <span
                className={css.optionCaption}
                title={option.name}
                id={option.name}
              >
                {option.name}
              </span>
            </label>
          )
        })}
      </Dialog>
    )
  }
}

export default ClassPickerDialog
