import { autorun, intercept, observable, extendObservable } from 'mobx'
import _debug from 'debug'
import { CircularDependencyFreeStore } from '../../../CircularDependencyFreeStore'
import { deepGet } from '../../obj'

const debug = _debug('geneva:COMMUNICATOR:DECORATOR')

const IS_SERVER = typeof window === 'undefined'

const diff = (a, b) => {
  return b.filter((i) => {
    return a.indexOf(i) < 0
  })
}

export default function (communicator) {
  let locks = []

  const createLock = (document) => {
    const user = document.user || document.users[0] || 'system'
    return {
      ...document,
      user,
      isMe: user.id && user.id === communicator.user.id,
    }
  }

  const lockItem = (itm, document) => {
    if (itm && itm.lock) {
      itm.lock(createLock(document))
      debug(
        `LOCKED: isLocked: ${itm.lockState.locked} by other user: ${itm.isLockedByOther}`
      )
    }
  }

  const releaseItem = (itm) => {
    if (itm && itm.release) {
      itm.release()
      debug(`UNLOCKED: ${itm.lockState.locked}`)
    }
  }

  // This enhances a store, but need to initCommunicator in constructor
  return (target) => {
    let oldId = null

    const provider = target.prototype || target

    const type = target.storeName

    provider.forceLock = function forceLock(lockable) {
      communicator.lock(lockable.type, lockable.id, true)
    }

    // Only add to PageStore prototype
    if (type === 'PageStore') {
      provider.handlePublishResult = function handlePublishResult(messageData) {
        if (!messageData.documents) {
          return
        }

        messageData.documents.forEach((data) => {
          const page = this.getById(data.id)
          const partialPage = this.getPartialById(data.id)

          delete data.id
          // do not parse the data!
          // - only changed data is received here
          // - parsing would create empty data structure assuming we have a new model
          // eslint-disable-next-line no-underscore-dangle
          data.__parsed = true

          // Update both if possible
          if (page) {
            page.set(data)
          }

          if (partialPage) {
            partialPage.set(data)
          }
        })

        const currentUserId = deepGet(
          CircularDependencyFreeStore,
          'authStore.user.id'
        )

        // Do not notify on UI when the publishing came from a different user
        if (currentUserId !== messageData.user.id) {
          return
        }

        messageData.action === 'published'
          ? this.notifyPublishing()
          : this.notifyUnpublishing()
      }
    }

    if (type === 'ReviewStore') {
      provider.handleComment = function handleComment(data) {
        if (!data) {
          return
        }
        this.addTempComment(data)
      }
    }

    provider.sendComment = function sendComment(message, action, targetName) {
      communicator.send({
        action: 'comment',
        messageAction: action ? action.toString() : 'comment',
        // todo: make this come dynamically from the review model
        room: this.current.id,
        message,
        target: targetName,
      })
    }

    provider.initCommunicator = function initCommunicator() {
      autorun(() => {
        // Listens to changes on the current. Once something becomes current
        // it will be locked.
        if (this.current && oldId !== this.current) {
          const id = this.current.id

          if (oldId) {
            communicator.release(type, oldId)
          }

          if (id) {
            communicator.lock(type, id)
            oldId = id
          }
        }
      })

      communicator.on('message', (data) => {
        if (data.action === 'error' && data.document.type === type) {
          const itm = this.getById(data.document.id)
          lockItem(itm, data.document)
        }
      })

      communicator.watch('message', (data) => {
        if (
          this.handlePublishResult
          && (data.action === 'published' || data.action === 'unpublished')
        ) {
          this.handlePublishResult(data)
        }
      })

      communicator.watch('comment', (data) => {
        if (this.handleComment) {
          this.handleComment(data)
        }
      })

      communicator.watch('listLock', (lockList) => {
        const newLocks = Object.keys(lockList)
          .map(key => lockList[key])
          .filter(lock => lock.type === type)
          .map(lock => lock.id)

        // lock all items that were added in the new list
        diff(locks, newLocks).forEach((id) => {
          const itm = this.getById(id)
          const completeLock = lockList[`${type}:${id}`]
          const lockedByCurrentUser = completeLock.users.find(
            user => communicator.user && user.id === communicator.user.id
          )

          if (!lockedByCurrentUser) {
            lockItem(itm, completeLock)
          }
          else {
            releaseItem(itm)
          }
        })

        // release all items that are missing in the new list
        diff(newLocks, locks).forEach((id) => {
          const itm = this.getById(id)

          releaseItem(itm)

          if (this.current && this.current.id === id) {
            lockItem(itm, {
              id,
              type,
              user: communicator.user,
            })
          }
        })

        locks = newLocks
      })
    }
  }
}

export const lockable = (target) => {
  const provider = target.prototype

  provider.initLockable = function initLockable() {
    this.lockState = observable({
      locked: true,
      user: 'system',
      isMe: false,
      id: null,
      type: null,
    })

    intercept(this.lockState, 'locked', (change) => {
      debug(`isLocked ID ${this.id}: ${change.newValue}`)
      return change
    })

    // enriches the used component with a function
    extendObservable(this, {
      isLockedByOther: () => {
        return IS_SERVER
          ? false
          : this.lockState.locked && !this.lockState.isMe
      },
    })

    extendObservable(this, {
      isLockedBySystem: () => {
        return this.lockState.user === 'system'
      },
    })
  }

  provider.lock = function lock(document) {
    Object.assign(this.lockState, {
      ...document,
      locked: true,
    })
  }

  provider.release = function release() {
    Object.assign(this.lockState, {
      locked: false,
      isMe: false,
      user: null,
      id: null,
      type: null,
    })
  }
}
