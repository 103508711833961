"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.dispatcher = exports.CommandInjectorContextPropTypes = void 0;
var PropTypes = __importStar(require("prop-types"));
var React = __importStar(require("react"));
var hoistNonReactStatics_1 = require("../../hoistNonReactStatics");
var Provider_1 = require("./Provider");
exports.CommandInjectorContextPropTypes = {
    dispatch: PropTypes.func,
    dispatchCommandInstance: PropTypes.func
};
// Inspired by mobx-react
function isStateless(component) {
    // `function() {}` has prototype, but `() => {}` doesn't
    // `() => {}` via Babel has prototype too.
    return !(component.prototype && component.prototype.render);
}
function mergeCommands(commands, origCommands) {
    if (origCommands === void 0) { origCommands = {}; }
    return Object.keys(commands)
        .reduce(function (memo, commandName) {
        if (commandName in memo) {
            if (memo[commandName] !== commands[commandName]) {
                console.warn("The command `" + commandName + "` is already defined on the " +
                    "parent props, but with a different implementation. Overwriting " +
                    "the parent definition!");
            }
            // skip that command as it is already defined with exactly the same class
            else {
                return memo;
            }
        }
        memo[commandName] = commands[commandName];
        return memo;
    }, __assign({}, (origCommands)));
}
function createCommandInjector(commands, component) {
    var displayName = "inject-commands-" +
        (component.displayName || component.name ||
            (component.constructor && component.constructor.name) || "Unknown");
    var Injector = /** @class */ (function (_super) {
        __extends(Injector, _super);
        function Injector() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.wrappedInstance = null;
            _this.injectorRef = function (instance) {
                _this.wrappedInstance = instance;
            };
            return _this;
        }
        Injector.prototype.getChildContext = function () {
            var self = this;
            return {
                dispatch: function (commandName, data, events) {
                    if (events) {
                        var command = self.context.dispatcher.createCommand(commandName, data);
                        self.bindEvents(command, events);
                        return self.context.dispatcher.execCommand(command);
                    }
                    return self.context.dispatcher.exec(commandName, data);
                },
                dispatchCommandInstance: function (command, data, events) {
                    if (data) {
                        command.set(data);
                    }
                    if (events) {
                        self.bindEvents(command, events);
                    }
                    self.context.dispatcher.execCommand(command);
                }
            };
        };
        Injector.prototype.bindEvents = function (command, events) {
            var _this = this;
            if (!this.commandEventDisposer) {
                this.commandEventDisposer = [];
            }
            Object.keys(events)
                .forEach(function (evtName) {
                _this.commandEventDisposer.push(command.subscribe(evtName, events[evtName]));
            });
        };
        Injector.prototype.componentWillUnmount = function () {
            // if (this.commandEventDisposer && this.commandEventDisposer.length) {
            //   this.commandEventDisposer.forEach((disposer) => disposer())
            // }
        };
        Injector.prototype.render = function () {
            var mergedCommands = mergeCommands(commands, this.props.commands);
            var newProps = __assign(__assign({}, this.props), { commands: mergedCommands });
            if (!isStateless(component)) {
                newProps.ref = this.injectorRef;
            }
            return React.createElement(component, newProps);
        };
        Injector.displayName = displayName;
        Injector.wrappedComponent = undefined;
        Injector.contextTypes = {
            dispatcher: Provider_1.DispatcherPropType
        };
        Injector.childContextTypes = exports.CommandInjectorContextPropTypes;
        return Injector;
    }(React.Component));
    // Static fields from component should be visible on the generated Injector
    hoistNonReactStatics_1.hoistNonReactStatics(Injector, component, []);
    Injector.wrappedComponent = component;
    return Injector;
}
function dispatcher(target) {
    target.contextTypes = target.contextTypes || {};
    target.contextTypes.dispatch = exports.CommandInjectorContextPropTypes.dispatch;
    target.contextTypes.dispatchCommandInstance = exports.CommandInjectorContextPropTypes.dispatchCommandInstance;
}
exports.dispatcher = dispatcher;
function default_1(commands) {
    return function (componentClass) {
        return createCommandInjector(commands, componentClass);
    };
}
exports.default = default_1;
