"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var command_1 = require("../lib/command");
var obj_1 = require("../obj");
var config_1 = require("../../config");
var CreateContentCommand = /** @class */ (function (_super) {
    __extends(CreateContentCommand, _super);
    /**
     * Creates a new CreateContentCommand. This command handles the content
     * creation based on a content type and template.
     * @param {Object} data - The spec of the content that should be created.
     * @param {string} data.type - The type of the content. One of `article` or
     * `widget`.
     * @param {object} [data.template] - The name of the template that should be
     * created.
     * @param {object} [data.template.id] - The id of the template.
     * @param {object} [data.template.name] - The name of the template.
     * @param {Object} [data.article] - The actual existing article.
     * @param {Object} [data.article.id] - The id of the article. This is required
     * if `data.article` is used!
     * @param {Object} [data.widget] - The actual existing widget.
     * @param {Object} [data.widget.id] - The id of the widget. This is required
     * if `data.widget` is used!
     */
    function CreateContentCommand(data) {
        var _this = _super.call(this, data) || this;
        _this.data = data;
        return _this;
    }
    /**
     * Executes the create command. Determines the template that needs to be
     * created and creates it. Then either uses an existing content, or creates
     * the content anew. Finally returns a promise that will resolve with the
     * newly created content.
     * @method exec
     * @return {Promise<Object>} - The promise resolving with the created content
     */
    CreateContentCommand.prototype.exec = function () {
        var _a = this.data, template = _a.template, preventNewArticle = _a.preventNewArticle;
        if (template && !preventNewArticle) {
            return this.createContent();
        }
        return this.tryGetContent();
    };
    CreateContentCommand.prototype.createContent = function () {
        var _a = this.data, type = _a.type, template = _a.template, store = _a.store;
        var contentStore = store[type];
        return contentStore.create({
            templateId: template.id,
            templateName: template.name,
            editorVersion: config_1.editorVersion || obj_1.deepGet(template, 'config.editorVersion')
        });
    };
    CreateContentCommand.prototype.tryGetContent = function () {
        var _a = this.data, type = _a.type, template = _a.template, store = _a.store;
        var content = this.data[type];
        var contentStore = store[type];
        var item = contentStore.getById(content.id);
        if (item.complete) {
            return Promise.resolve(item);
        }
        return item.load();
    };
    CreateContentCommand.storeRequirements = {
        article: true,
        widget: true,
    };
    /**
     * Validates the given input data.
     * @method validate
     * @param  {Object} data - The data to be validated.
     * @return {String}      - The result of validation.
     */
    CreateContentCommand.validationSpec = {
        type: command_1.Command.ensureData.isPresent.oneOf(['article', 'widget']),
        'template | article | widget': command_1.Command.ensureData.either({
            template: {
                'template.id': { number: true },
                'template.name': { string: true }
            },
            article: {
                'article.id': { number: true },
            },
            widget: {
                'widget.id': { number: true },
            }
        })
    };
    return CreateContentCommand;
}(command_1.Command));
exports.default = CreateContentCommand;
