"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var context_1 = require("../../context");
var obj_1 = require("../../shared/obj");
var DistinctModelObserver = /** @class */ (function () {
    function DistinctModelObserver(_a) {
        var store = _a.store;
        this.data = {};
        this.store = store;
        this.refCounter = {};
        this.refComponentNames = {};
        this.refStore = {};
    }
    DistinctModelObserver.fromProps = function (deepPropName) {
        return function (props) {
            return obj_1.deepGet(props, deepPropName);
        };
    };
    DistinctModelObserver.fromContext = function (deepPropName) {
        return function () {
            return obj_1.deepGet(context_1.store.getCurrentIds(), deepPropName);
        };
    };
    DistinctModelObserver.prototype.init = function (modelSpecs, props) {
        var _this = this;
        var newObservables = modelSpecs.reduce(function (memo, spec) {
            var key = _this.getKeyFromSpec(spec);
            var id = _this.getIdFromSpec(spec, props);
            var store = _this.store[spec.type];
            if (store && id) {
                var model = _this.store[spec.type].getById(id);
                // if there is already a model with the same key
                if (key in _this.data) {
                    // same model, everthing alright, resume
                    if (_this.data[key] === model) {
                        return memo;
                    }
                    // new model with the same key was created.
                    // Happens when a component updates with a new e.g. article
                    // or it never gets unmounted or disposed
                    var newRef = _this.getCounterRefId(spec, props);
                    var oldRef = _this.getOldCounterRefId(key);
                    if (spec.component === _this.refComponentNames[key]) {
                        // same component as last time, everything alright
                        // but remove the old ref
                        _this.removeRefCounterById(oldRef, key);
                        console.warn("New viewModel " + key + " created for " + spec.component);
                    }
                    else {
                        // another component tries to create a viewModel with a key already used.
                        // this can cause serious data problems
                        throw new Error("DistinctModelObserver: The key `" + key + "` is already used for " + _this.refComponentNames[oldRef]);
                    }
                }
                memo[_this.getKeyFromSpec(spec)] = model;
            }
            return memo;
        }, {});
        // create or overwrite viewModel
        mobx_1.extendObservable(this.data, newObservables);
    };
    DistinctModelObserver.prototype.dispose = function (modelSpecs, props) {
        var _this = this;
        var newObservables = modelSpecs.forEach(function (spec) {
            _this.removeRef(spec, props);
        });
    };
    DistinctModelObserver.prototype.collect = function (modelSpecs, props) {
        var _this = this;
        var newObservables = modelSpecs.forEach(function (spec) {
            _this.addRef(spec, props);
        });
    };
    DistinctModelObserver.prototype.getKeyFromSpec = function (spec) {
        var as = typeof spec.as === 'function' ? spec.as() : spec.as;
        return as;
    };
    DistinctModelObserver.prototype.getIdFromSpec = function (spec, props) {
        return typeof spec.id === 'function' ? spec.id(props) : spec.id;
    };
    DistinctModelObserver.prototype.getCounterRefId = function (spec, props) {
        return spec.type + ":" + this.getIdFromSpec(spec, props) + ":" + this.getKeyFromSpec(spec);
    };
    DistinctModelObserver.prototype.getOldCounterRefId = function (key) {
        return this.refStore[key];
    };
    DistinctModelObserver.prototype.getRefComponentName = function (spec) {
        return spec.component;
    };
    DistinctModelObserver.prototype.addRef = function (spec, props) {
        var refCounterId = this.getCounterRefId(spec, props);
        var refComponentName = this.getRefComponentName(spec);
        var key = this.getKeyFromSpec(spec);
        if (!(refCounterId in this.refCounter)) {
            this.refCounter[refCounterId] = 0;
        }
        this.refCounter[refCounterId] += 1;
        this.refComponentNames[key] = refComponentName;
        this.refStore[key] = refCounterId;
    };
    DistinctModelObserver.prototype.removeRef = function (spec, props) {
        var refCounterId = this.getCounterRefId(spec, props);
        var key = this.getKeyFromSpec(spec);
        this.refCounter[refCounterId] -= 1;
        if (this.refCounter[refCounterId] <= 0) {
            delete this.refCounter[refCounterId];
            delete this.data[key];
            delete this.refComponentNames[key];
            delete this.refStore[key];
        }
    };
    DistinctModelObserver.prototype.removeRefCounterById = function (refCounterId, key) {
        this.refCounter[refCounterId] -= 1;
        if (this.refCounter[refCounterId] <= 0) {
            delete this.refCounter[refCounterId];
        }
    };
    __decorate([
        mobx_1.observable
    ], DistinctModelObserver.prototype, "data", void 0);
    return DistinctModelObserver;
}());
exports.default = DistinctModelObserver;
