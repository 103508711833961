import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'

import {
  editor as editorConfig,
  //  features,
  isProduction
} from '../../config'
import { LoginForm, store as authStore } from '../../auth'
import { store as uiStore } from '../../ui'
import { ArticleContainer } from '../../article'
import { WidgetContainer } from '../../widget'
import { ControlCenterContainer } from '../../project'
import { TableDialog } from '../../template'
import { Editor as ImageEditor } from '../../image'
import MediaManager from '../../mediamanager'
import Bynder from '../../bynder'
import Censhare from '../../censhare'
import ContentLoadingBoxFullscreen from '../../shared/components/ContentLoadingBoxFullscreen'

import { ADMIN_MODE } from '../../shared/const'
import { PluginHook } from '../../shared/plugin'
import AppLoader from '../../shared/components/AppLoader'
import { ignore } from '../../shared/obj'
import { hasPermission } from '../../shared/utils/user-rights'

// import allowedFeatures from '../allowedFeatures'

import AppDevTools from '../components/AppDevTools'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

// TODO: load this user local dependant
require('moment/locale/de')

@observer
class AppContainer extends Component {

  // todo: convert article to articleStore. update here and in parent and child locations
  static propTypes = {
    article: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    context: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    communicator: PropTypes.object.isRequired,
  }

  componentWillMount() {

    // Start with loading the default language.
    this.loadLang((window.navigator.userLanguage || window.navigator.language).includes('en')
      ? 'en'
      : 'de')

  }

  componentDidMount() {

    // Try to get the user currently in the session from the server
    authStore.loadCurrentUser()

    // APPROVED: Whenever guiIso of the user object changes, load that language file
    this.handler = autorun(() => {

      if (this.props.auth.user && this.props.auth.user.guiIso) {
        this.loadLang(this.props.auth.user.guiIso)
      }

    })

    document.addEventListener('dragover', this.handleDragHover, true)
    document.addEventListener('drop', this.handleEndDragDrop, true)
    document.addEventListener('dragend', this.handleEndDragDrop, true)

  }

  componentWillUnmount() {

    if (this.handler) {
      this.handler()
      this.handler = null
    }

    document.removeEventListener('dragover', this.handleDragHover, true)
    document.removeEventListener('drop', this.handleEndDragDrop, true)
    document.removeEventListener('dragend', this.handleEndDragDrop, true)
  }

  loadLang(lang = 'en') {
    if (uiStore.language !== lang || !uiStore.translations) {
      uiStore.loadLang(lang)
    }
  }

  @autobind
  handleDragHover(e) {

    if (this.dragOverTimeout) {
      clearTimeout(this.dragOverTimeout)
    }

    this.dragOverTimeout = setTimeout(this.handleEndDragDrop, 100)

    let dragAction = null

    const data = e.dataTransfer

    if (
      e.type === 'dragover'
      && data.types != null
      && data.types.length
    ) {

      dragAction = {
        active: true,
        types: data.types.map(type => type)
      }

      // http://stackoverflow.com/questions/6848043/how-do-i-detect-a-file-is-being-dragged-rather-than-a-draggable-element-on-my-pa
      // data.types.contains('application/x-moz-file')

    }

    this.props.ui.setGlobalState('dragAction', dragAction)

  }

  @autobind
  handleEndDragDrop() {

    setTimeout(() => {
      this.props.ui.setGlobalState('dragAction', null)
    }, 100)

  }

  renderContent() {

    const { auth } = this.props

    return !auth.user || auth.error
      ? this.renderLogin()
      : this.renderMainContent()
  }

  renderLogin() {
    // redirect to the AM and pass the current url to allow the AM to bring the
    //  user back to this path

    // check if admin mode to go to cms login
    let ending = ''
    const adminMode = window.location.search.substr(1).split('&').find(item => item === ADMIN_MODE)
    if (adminMode) {
      return (<LoginForm auth={this.props.auth} />)
    }

    if (window.location.pathname) {
      ending = `?redirectUrl=${encodeURIComponent(window.location)}`
    }

    window.location.href = `${global.GENEVA_CONFIG.AM_URL}${ending}`

    return null
  }

  renderMainContent() {

    const pluginProps = ignore(this.props, ['children'])

    const hasTableButton = editorConfig.buttons.indexOf('insertTable') !== -1

    const { ui, children } = this.props

    const { paneSplit } = ui

    return (<div
      className={classNames(
        css.appLayout,
        'grid-block',
        'vertical',
        'inject-anchor',
        'noscroll'
      )}
    >

      <div
        className={classNames(css.appMainView, paneSplit, 'grid-block', 'vertical')}
      >
        {children}
      </div>


      <ArticleContainerDisplayer key="articleContainerDisplayer" {...this.props} paneSplit={paneSplit} />

      <WidgetContainerDisplayer key="widgetContainerDisplayer" {...this.props} paneSplit={paneSplit} />

      <ImageEditorDisplayer ui={ui} paneSplit={paneSplit} />

      <ContentLoadingBoxFullscreenDisplayer ui={ui} paneSplit={paneSplit} />

      <MediaManagerDisplayer ui={ui} />

      <BynderDisplayer ui={ui} />

      <CenshareDisplayer ui={ui} />

      <ControlCenterDisplayer key="controlCenterDisplayer" {...this.props} />

      <PluginHook key="pluginHook" hook="app" {...pluginProps} />

      {!isProduction
        // todo: fully remove appDevTools as it is not used
        ? null
        : null
      }


      {hasTableButton
        ? <TableDialog modal={ui.tableDialog} />
        : null
      }

      {/* allowedFeatures
      .map((feature) => {

        if (feature.name in features) {
          const featureConfig = features[feature.name]
          const Comp = feature.Comp
          return (<Comp
            {...feature.getProps(pluginProps)}
            featureConfig={featureConfig}
          />)
        }

        return null
      })*/}

    </div>)

  }

  renderLoading() {
    return this.renderSimpleMessage(
      this.props.ui.translations
        ? null
        : 'Please wait while language is being loaded...'
    )
  }

  renderConnectingToDatabase() {
    const { error } = this.props.auth

    return this.renderSimpleMessage(
      error
        ? `An error occured: ${error.message}`
        : 'Connecting to server...'
    )
  }

  renderSimpleMessage(msg = '') {
    return (<AppLoader>{msg}</AppLoader>)
  }

  renderWaitingForCommunicator() {
    return this.renderSimpleMessage('Waiting for communicator...')
  }

  render() {

    let children
    const { auth, ui, communicator, project } = this.props

    // hack to rerender when the project is loaded
    const hasCurrent = project.hasCurrent

    if (!(auth.user || auth.error)) {
      children = this.renderConnectingToDatabase()
    }
    else {

      if (ui.translations) {

        if (auth.user && !communicator.isReady) {
          children = this.renderWaitingForCommunicator()
        }
        else {
          children = this.renderContent()
        }
      }
      else {
        children = this.renderLoading()
      }

    }

    return (<div
      className={`grid-frame
        noscroll
        ${css.appContainer}`}
    >
      {children}
    </div>)

  }

}

export default AppContainer

const WidgetContainerDisplayer = (props) => {
  return (props.params.widgetAction && props.params.widgetId)
    ? (<div
      className={classNames(css.appOverlayEditor, props.paneSplit)}
    >
      <WidgetContainer {...props} />
    </div>)
    : null
}
WidgetContainerDisplayer.propTypes = {
  params: PropTypes.object
}

const ArticleContainerDisplayer = (props) => {
  // requires: articleAction, id, and project loaded with a 'current'
  return (props.params.articleAction && props.params.articleId && props.project.hasCurrent)
    ? (<div
      className={classNames(css.appOverlayEditor, props.paneSplit)}
    >
      <ArticleContainer {...props} />
    </div>)
    : null
}
ArticleContainerDisplayer.propTypes = {
  params: PropTypes.object
}

const MediaManagerDisplayer = observer(({ ui }) => {
  return (ui.mediaManagerDialog.status === 'editing')
    ? <div
      className={classNames(css.appOverlayEditor)}
    >
      <MediaManager dialog={ui.mediaManagerDialog} />
    </div>
    : null
})

const BynderDisplayer = observer(({ ui }) => {
  return (ui.bynderDialog.status === 'editing')
    ? <div
      className={classNames(css.appOverlayEditor)}
    >
      <Bynder dialog={ui.bynderDialog} />
    </div>
    : null
})

const CenshareDisplayer = observer(({ ui }) => {
  return (ui.censhareDialog.status === 'editing')
    ? <div
      className={classNames(css.appOverlayEditor)}
    >
      <Censhare dialog={ui.censhareDialog} />
    </div>
    : null
})

const ImageEditorDisplayer = observer(({ ui, paneSplit }) => {
  return (ui.imageEditorDialog.status === 'editing')
    ? <div
      className={classNames(css.appOverlayEditor, paneSplit)}
    >
      <ImageEditor editor={ui.imageEditorDialog} />
    </div>
    : null
})

const ContentLoadingBoxFullscreenDisplayer = observer(({ ui, paneSplit }) => {
  return (ui.contentLoadingBoxFullscreenDialog.status === 'editing')
    ? <div
      className={classNames(css.appOverlayEditor, css.appOverlayLoader, paneSplit)}
    >
      <ContentLoadingBoxFullscreen {...ui.contentLoadingBoxFullscreenDialog} />
    </div>
    : null
})

const ControlCenterDisplayer = (props) => {
  if (!hasPermission('controlCenter')) {
    return <div></div>
  }
  return (props.params.subview === 'control-center')
    ? (<div
      className={classNames(css.appOverlayEditor)}
    >
      <ControlCenterContainer
        {...props}
        projectStore={props.project}
      />
    </div>)
    : null
}
ControlCenterDisplayer.propTypes = {
  params: PropTypes.object
}
