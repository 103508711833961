import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../translations'

import Navbar, {
  FlexSection,
  FixedSection
} from '../../shared/components/Navbar'
import GenevaButton from '../../ui/components/GenevaButton'
import { store as authStore } from '../../auth'
import * as css from '../styles.scss'

@observer
export default class MediaManagerNavbar extends Component {

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    navbarStyle: PropTypes.string,
    submitDisabled: PropTypes.bool,
    searchValue: PropTypes.string,
    imageCount: PropTypes.number,
  }

  static contextTypes = {
    router: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.OS = (navigator.appVersion.indexOf('Mac') !== -1)
      ? 'MacOS'
      : 'WindowsOS'
  }

  @autobind
  handleInputChange(e) {
    if (this.props.handleInputChange) {
      this.props.handleInputChange(e)
    }
  }

  @autobind
  handleCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  @autobind
  handleSubmit() {
    if (this.props.onSubmit) {
      this.props.onSubmit()
    }
  }

  renderBrand() {
    const mmApp = authStore.user && authStore.user.apps && authStore.user.apps.mm
      ? authStore.user.apps.mm
      : { short: 'MM' } // fallback

    return (
      <FixedSection>
        <div className={classNames(css.brand, this.OS)}>
          GENEVA
        </div>
        <div className={css.appIcon}
          style={{
            backgroundColor: '#ffffff'
          }}
        >
          {mmApp.short.toUpperCase()}
        </div>
      </FixedSection>
    )
  }

  render() {

    const {
      navbarStyle,
      imageCount,
      searchValue,
    } = this.props

    return (
      <Navbar
        navbarStyle={navbarStyle}
      >

        {this.renderBrand()}

        <FixedSection className="navbar-text-container">

          <FlexSection>
            {formatMessage({ id: 'mm.navbar-text' }, { count: imageCount })}
          </FlexSection>

          <GenevaButton
            onClick={this.handleCancel}
            className="small button"
          >
            <FormattedMessage id="mm.cancel" />
          </GenevaButton>
          <GenevaButton
            onClick={this.handleSubmit}
            className="small button"
            disabled={this.props.submitDisabled}
          >
            <FormattedMessage id="mm.submit" />
          </GenevaButton>
        </FixedSection>

        <FixedSection>
          <span className="inline-label">
            <input
              id="searchValue"
              type="search"
              value={searchValue}
              onChange={this.handleInputChange}
              placeholder={formatMessage({ id: 'mm.search-placeholder' })}
            />
            <a className="button inline-icon">
            </a>
          </span>
        </FixedSection>

      </Navbar>
    )
  }

}
