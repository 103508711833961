"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var command_1 = require("../../../shared/lib/command");
var SelectContentInWidgetCommand = /** @class */ (function (_super) {
    __extends(SelectContentInWidgetCommand, _super);
    function SelectContentInWidgetCommand(data) {
        if (data === void 0) { data = {}; }
        return _super.call(this, data) || this;
    }
    SelectContentInWidgetCommand.prototype.exec = function () {
        var _a = this.data, widget = _a.context.widget, _b = _a.item, item = _b === void 0 ? null : _b, _c = _a.index, index = _c === void 0 ? undefined : _c;
        return new Promise(function (resolve /* , reject*/) {
            if (widget.handleSelectItem) {
                widget.handleSelectItem({ target: { item: item, index: index } });
            }
            else {
                console.warn(widget.constructor.name + " should implemenet the " +
                    'method "handleSelectItem({target: { item }})": \n' +
                    "\n            handleSelectItem({ target: { item, index } }) {\n              const { content } = this.props\n\n              if (!item && index !== undefined) {\n                if (content.length) {\n                  index = index === -1 ? content.length - 1 : index\n                  item = content[index]\n                }\n                else {\n                  item = null\n                }\n              }\n\n              this.setState({\n                selectedItem: item\n              })\n            },\n          ");
            }
            resolve();
        });
    };
    SelectContentInWidgetCommand.storeRequirements = {
        context: true,
    };
    SelectContentInWidgetCommand.validationSpec = {
        context: command_1.Command.ensureData.isPresent,
        'context.widget': command_1.Command.ensureData.isObject,
    };
    return SelectContentInWidgetCommand;
}(command_1.Command));
exports.default = SelectContentInWidgetCommand;
