import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ignore } from '../../../shared/obj'
import { eventHandlers } from '../../../shared/react-helpers'

class Linkable extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string,
    }),
    children: PropTypes.node,
  };

  render() {
    const { value, className } = this.props

    const dataAttribs = Object.keys(this.props).reduce((memo, key) => {
      if (key.indexOf('data') === 0) {
        memo[key] = this.props[key]
      }
      return memo
    }, {})

    const aProps = ignore(value || {}, ['appearance', 'command', 'content'])

    const handlers = {
      ...eventHandlers(this.props),
      onClick: this.handleClick,
    }

    if (!(value && value.href)) {
      return this.props.children ? this.props.children : null
    }

    return (
      <a className={className} {...aProps} {...dataAttribs} {...handlers}>
        {this.props.children || null}
      </a>
    )
  }
}

export { Linkable as default }
